@use "../../styles/variables";
@use "../../styles/mixins";

.header {
  position: fixed;
  top: 0;
  height: 136px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // z-index: 500;
  background: rgba(15, 15, 15, 0.3);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: 100;

  &.active {
    background: rgba(15, 15, 15, 0.5);
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  color: variables.$color-primary;
}

.logo {
  position: relative;
  width: 120px;
  height: 120px;

  &__link {
    position: absolute;
    width: 120px;
    height: 120px;
  }
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  text-transform: capitalize;

  &__link {
    display: inline-flex;
    color: variables.$color-white;
    padding-bottom: 8px;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease-in-out;

    &:hover {
      text-shadow: 0 0 0.65px variables.$color-white,
        0 0 0.65px variables.$color-white;
      border-bottom: 1px solid variables.$color-white;
    }

    &.active {
      text-shadow: 0 0 0.65px variables.$color-white,
        0 0 0.65px variables.$color-white;
      border-bottom: 1px solid variables.$color-white;
    }
  }

  @media screen and (max-width: 890px) {
    display: none;
  }
}

.social_icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  &__icon {
    position: relative;
    width: 28px;
    height: 28px;

    svg {
    }

    @media screen and (max-width: 550px) {
      display: none;
    }
  }

  &__nav_toggle {
    display: none;
    @media screen and (max-width: 890px) {
      display: block;
      @include mixins.buttonReset;
      font-size: 30px;
      color: variables.$color-white;
    }
  }
}

.switch {
  @include mixins.buttonReset;
  width: 48px;
  height: 48px;
  position: relative;
}
