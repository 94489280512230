@use "../../styles/variables";
@use "../../styles/mixins";

.sidebar {
  background-color: variables.$color-white;
  position: fixed;
  z-index: 1000;
  height: 100vh;
  overflow: hidden;
  transition: all 0.2s ease-in;
  width: 0;

  &.active {
    width: 300px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  &__toggler {
    @include mixins.buttonReset;
    color: variables.$color-gray-dark;
    border-radius: 50%;
    padding: 8px;
    font-size: 25px;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;

  &__link {
    @include mixins.buttonReset;
    font-size: 18px;
    // text-align: left;
    padding: 8px 0;
    text-transform: capitalize;
    letter-spacing: 2px;
    color: variables.$color-gray-dark;

    &:hover {
      color: variables.$color-gray-dark;
    }

    &.active {
      color: variables.$color-gray-dark;
    }
  }
}
